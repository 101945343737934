<template>
  <layout width="540">
    <template v-slot:header>
      Notification info
    </template>

    <template>
      <v-form class="d-flex flex-column">
        <div class="item">
          <span class="subtitle-2 black--text mr-2">Sender: </span>
          <span class="body-2">{{ item.notification.userByBy.mName }}</span>
        </div>
        <div class="item">
          <span class="subtitle-2 black--text mr-2">Time: </span>
          <span class="body-2">{{ item.notification.createdAt }}</span>
        </div>
        <div class="item">
          <span class="subtitle-2 black--text mr-2">ID: </span>
          <span class="body-2">{{ item.notification.id }}</span>
          <v-icon class="ml-auto" @click="copyId" v-text="'$copy'" />
        </div>
        <div class="item">
          <div class="subtitle-2 black--text mr-2">Tags:</div>
        </div>
        <div class="chip-wrapper">
          <v-chip
            v-for="tag in item.notification.tags"
            :key="tag"
            small
            color="primary"
            text-color="white"
            >{{ tag }}
          </v-chip>
        </div>
        <div class="item">
          <div class="subtitle-2 black--text mr-2">Message:</div>
        </div>
        <div class="body-2">{{ item.notification.message }}</div>
      </v-form>
    </template>
    <template v-slot:footer>
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="copy">
        Copy
      </v-btn>
      <v-btn text color="primary" depressed @click.stop="$emit('close')">
        Close
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { useCopy } from '@/compositions/copy.js';
import Vue from 'vue';
import { computed } from '@vue/composition-api';

export default Vue.extend({
  name: 'ObjectGroupEdit',
  components: {
    Layout
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { copyWithAlert } = useCopy();

    const rows = computed(() => {
      return {
        ID: props.item.notification.id,
        Sender: props.item.notification.userByBy.mName,
        Tags: props.item.notification.tags.join(' | '),
        Time: props.item.notification.createdAt,
        Message: props.item.notification.message
      };
    });

    const copy = () => {
      copyWithAlert(JSON.stringify(rows.value));
    };

    const copyId = () => {
      copyWithAlert(JSON.stringify(props.item.notification.id));
    };

    return {
      copy,
      copyId,
      rows
    };
  }
});
</script>

<style lang="scss">
.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.item {
  height: 48px;
  display: flex;
  align-items: center;
}
</style>
